<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
           style="width: 150px"
          :src="appDarkLogo"
          alt="logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Find Host V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Find Host-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        v-if="hostResults.length == 0"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Find My Organization 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email or your Organization name and we'll redirect you to your Organization sub-domain.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Email or Name"
                  rules="required"
                >
                <label class="required-l" for="forgot-password-email">Email or Name</label>
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="youremail@amazon.com or amazon"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Find Now
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to Homepage
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Find Host-->
      <!-- Select Host-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        v-else
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Select Organization 🔒
          </b-card-title>

          <b-list-group>
            <b-list-group-item button v-for="link in hostResults" @click="redirect(link)">{{link.company_name}}({{link.domain_email}}) {{link.domain}}</b-list-group-item>
          </b-list-group>

          <b-button class="text-center mt-2" @click="findAnother()"
                variant="primary"
                block
              >
              Find Another
              </b-button>
          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to Homepage
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Select Host-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      hostResults:[],
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      const self = this
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http.post('/find-host', { email: self.userEmail })
            .then(res => {
              if (res.data.success) {
                this.userEmail = ''
                this.$refs.simpleRules.reset();
                this.hostResults = res.data.data;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Organization Found',
                    icon: 'BellIcon',
                    text: 'Please Select your Organization & you will be redirected to login page',
                    variant: 'success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Organization Not Found',
                    icon: 'BellIcon',
                    text: res.data.message,
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              // Do something on error
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Organization search failed',
                    icon: 'BellIcon',
                    text: 'Oops! Something Went Wrong, Please try again later.',
                    variant: 'danger',
                  },
                })
            })
        }
      })
    },
    findAnother(){
      this.hostResults = [];
    },
    redirect(link){
      window.location = 'https://'+link.domain+'/login';
    }
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app
    return {
      appName,
      appDarkLogo,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
    .brand-text{
        line-height: 42px;
        margin-bottom: 0;
    }
</style>
